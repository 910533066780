import * as actions from "../actions"
import asyncActions from "../async-actions"

import { catchMessage } from "./environment"

import * as vega from "vega"

import moment from "moment"

export function getVegaSpec(file, local) {
  return async function (dispatch) {
    let url = file
    if (local) {
      url = `${process.env.PUBLIC_URL}/static/specs/${file}.json`
    }
    return fetch(url)
      .then(response => response.json())
      .then(json => {
        if (!json || json && json.exception) {
          catchMessage(json, dispatch, "getVegaSpec")
          return json && (json.type === "success" || json.type === "info") ? json : null
        } else {
          return json
        }
      })
      .catch(function (error) {
        catchMessage({ exception: error }, dispatch, "getVegaSpec")
      })
  }
}

export function sendSignal(view, { signalName, value }) {
  return async function (dispatch) {
    if (view) {
      if (view.getState().signals.hasOwnProperty(signalName)) {
        await view.signal(signalName, value).runAsync()
      }
    }
  }
}

export function getVegaData(view, key, url) {
  return async function (dispatch) {
    return await fetch(url)
      .then(response => response.json())
      .then(json => {
        dispatch(updateVegaData(view, key, json))
        return json
      })
      .catch(function (error) {
        catchMessage({ exception: error }, dispatch, "getVegaData")
      })
  }
}

export function updateVegaData(view, key, data) {
  return async function (dispatch) {
    if (view) {
      await view.remove(key, vega.truthy).runAsync()
      await view.insert(key, data).runAsync()

      return data
    }
  }
}
