import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import asyncActions from "../state/async-actions"

import {
  Button,
  CircularProgress,
  Paper,
  Typography,
  useTheme
} from "@mui/material"

import {
  ChevronLeftRounded,
  ChevronRightRounded
} from "@mui/icons-material"

import HKVVega from "../components/HKVVega/4.0"

import {
  useNavigate,
  useParams
} from "react-router-dom"

function Events(props) {
  const { dispatch } = props

  const theme = useTheme()

  const [events, setEvents] = useState([])
  const [eventData, setEventData] = useState({})

  const [vegaRefs, setVegaRefs] = useState({})

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      dispatch(asyncActions.fetchLocalFile(`${process.env.PUBLIC_URL}/static/events/${params.id}.json`, "json"))
        .then((json) => {
          if (json) {
            setEventData(json)
          } else {
            setEventData(null)
          }
        })
    } else {
      dispatch(asyncActions.fetchLocalFile(`${process.env.PUBLIC_URL}/static/events.json`, "json"))
        .then((json) => {
          if (Array.isArray(json)) {
            setEvents(json)
          } else {
            setEvents(null)
          }
        })
    }
  }, [params.id])

  const renderEvents = () => {
    if (!events) return <Typography sx={{ marginTop: theme.spacing(0.5) }}>Geen events kunnen ophalen</Typography>
    if (!events.length === 0) return <CircularProgress size={24} sx={{ margin: theme.spacing(1, 0, 0) }} />
    return events.map((event, index) => (
      <Button
        key={index}
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ marginTop: theme.spacing(1) }}
        onClick={() => navigate(`/events/${event.path}`)}
        endIcon={<ChevronRightRounded />}
      >
        {event.name}
      </Button>
    ))
  }

  const vegaDidMount = (vegaRef) => {
    if (eventData && Object.keys(eventData).length > 0) {
      Object.keys(eventData).forEach((key, i) => {
        dispatch(asyncActions.updateVegaData(vegaRef, key, eventData[key]))
          .then(() => {
            if (i === (Object.keys(eventData).length - 1)) {
              dispatch(asyncActions.loaderMin())
            }
          })
      })
    } else {
      dispatch(asyncActions.loaderMin())
    }

    setVegaRefs(vegaRefs => {
      vegaRefs[vegaRef.key] = vegaRef
      return { ...vegaRefs }
    })
  }

  const renderContent = () => {
    if (params.id) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            startIcon={<ChevronLeftRounded />}
            onClick={() => navigate("/events")}
            sx={{ marginBottom: theme.spacing(4) }}
          >
            Terug naar events
          </Button>
          <div
            style={{
              position: "relative",
              overflow: "auto",
              width: "100%",
              height: "100%"
            }}
          >
            {eventData && Object.keys(eventData).length > 0
              ? <HKVVega
                config={{ specName: "events", key: "events" }}
                dispatch={dispatch}
                vegaDidMount={vegaDidMount}
                style={{ width: 520, height: 520 }}
              />
              : <Typography>No data for "{params.id}"</Typography>
            }
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            startIcon={<ChevronLeftRounded />}
            onClick={() => navigate("/")}
            sx={{ marginBottom: theme.spacing(2) }}
          >
            Terug naar rainguru
          </Button>
          <Typography variant="h5">Events:</Typography>
          <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", alignItems: "start" }}>
            {renderEvents()}
          </div>
        </React.Fragment>
      )
    }
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",

        margin: "auto",
        padding: theme.spacing(3),
        boxSizing: "border-box",
        width: 600,
        maxWidth: `calc(100% - ${theme.spacing(8)})`,

        minHeight: 250,
        maxHeight: `calc(100% - ${theme.spacing(8)})`,

        overflow: "hidden"
      }}
      elevation={3}
    >
      {renderContent()}
    </Paper>
  )
}

export default connect(state => state)(Events)