import asyncActions from "../async-actions"

export function catchMessage(json, dispatch, functionName, successText) {
  let message = "Error"
  let type = "error"
  if (json) {
    if (json.type === "success" || successText) {
      if (successText) {
        message = successText
        type = json.type ? json.type : "info"
      } else {
        return null
      }
    } else {
      if (json.exception) {
        message = json.exception
        type = json.type || type

        // Voor als we met een bepaalde reden een foutmelding krijgen
        if (json.attributes && json.attributes.reden) {
          message = json.attributes.reden
          type = "success"
        }
      } else {
        return null
      }
    }
  }

  dispatch(asyncActions.setError({
    message: message,
    number: json && json.number,
    function: functionName,
    type: type
  }))
}

export function getAppId() {
  return window.env.REACT_APP_ID
}

export function getAppType() {
  return window.env.REACT_APP_TYPE
}

export function getDisclaimer() {
  return window.env.REACT_APP_DISCLAIMER
}

export function getApiUrl() {
  return window.env.REACT_APP_DROOGTESTATISTIEK_API_URL
}

export function getMangroveUrl() {
  return window.env.REACT_APP_MANGROVE_URL
}

export function getGeoServerUrl() {
  return window.env.REACT_APP_GEOSERVER_URL
}

export function getThreddsUrl() {
  return window.env.REACT_APP_THREDDS_URL
}

export function getUrl(functionName) {
  return `${getMangroveUrl()}/entry.asmx/Call?function=${functionName}`
}

export function getUploadUrl(functionName) {
  return `${getMangroveUrl()}/entry.asmx/Upload?function=${functionName}`
}