import moment from "moment"

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function checkForTooltip(event, id) {
  const element = document.getElementById(id)

  // Checken of ellipsis is toegepast op de typography, zo ja toon de tooltip anders niet
  if (element && event.target.offsetWidth < event.target.scrollWidth) {
  } else {
    element.style.display = "none"
  }
}

export function hexToRgbA(hex, opacity) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
  }
  throw new Error('Bad Hex')
}

export function filterByKey(data, key) {
  return Object.values(
    data.reduce((c, e) => {
      if (!c[e[key]]) c[e[key]] = e
      return c
    }, {})
  )
}

export function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export async function makeRequest(url, functionName, method, parameters, body) {
  const token = window.AUTH_TOKEN
  return await new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()

    if ("withCredentials" in xhr) {
      xhr.open(method, url, true)

      token && xhr.setRequestHeader("Authorization", `Bearer ${token}`)
      xhr.setRequestHeader("Function", functionName)
      xhr.setRequestHeader("Parameters", JSON.stringify(parameters))
    }

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response)
      } else {
        resolve({ exception: xhr.statusText })
      }
    }

    xhr.onerror = function () {
      resolve({ exception: xhr.statusText })
    }

    xhr.withCredentials = false

    if (body instanceof FormData) {
      xhr.send(body)
    } else {
      xhr.send(JSON.stringify(body))
    }
  })
}

// Deep mergen van object
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function sortByKey(array, key, desc) {
  const sorted = array.slice().sort(function (a, b) {
    var x = a[key]; var y = b[key];

    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
  })

  if (desc === "desc") {
    return sorted.reverse()
  } else {
    return sorted
  }
}