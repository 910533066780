import React from "react"
import ReactMarkdown from "react-markdown"

import "./HKVMarkdown.css"

class HKVMarkdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { config } = this.props

    return (
      <div className={config.class}>
        <ReactMarkdown children={config.source} linkTarget="_system" />
      </div>
    )
  }
}

export default HKVMarkdown
