import i18nextXHRBackend from "i18next-http-backend"

class i18nBackend extends i18nextXHRBackend {
  constructor(services, options) {
    super(services, options)

    this.options = {
      ...this.options,
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    }
  }
}

export default i18nBackend