import React from "react"

import IconMenu from "./IconMenu"
import SubMenu from "./SubMenu"

function HKVDrawerIconMenu(props) {
  const {
    disclaimer,
    help,
    languages,
    menuItems,
    onToggle,
    openDialog,
    privacyStatement,
    renderDrawerContent,
    selectedItem,
    selectedLanguage,
    setSelectedItem,
    setSelectedLanguage,
    signout
  } = props

  const onItemClick = (menuItem) => {
    if (menuItem.drawerItems) {
      setSelectedItem(selectedItem && selectedItem.key === menuItem.key ? null : menuItem)
    } else {
      setSelectedItem(null)
    }
  }

  const drawerStateChanged = (placement, state) => {
    if (state === false) {
      setSelectedItem(null)
    }
    onToggle(placement, state)
  }

  return (
    <React.Fragment>
      <IconMenu
        disclaimer={disclaimer}
        help={help}
        languages={languages}
        menuItems={menuItems}
        onItemClick={onItemClick}
        onToggle={drawerStateChanged}
        openDialog={openDialog}
        privacyStatement={privacyStatement}
        signout={signout}
        selectedItem={selectedItem}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      >
        {props.children}
      </IconMenu>
      <SubMenu
        onToggle={drawerStateChanged}
        renderDrawerContent={renderDrawerContent}
        selectedItem={selectedItem}
      />
    </React.Fragment>
  )
}

export default HKVDrawerIconMenu