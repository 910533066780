import vegaEmbed, { vega } from "vega-embed"

export async function createVega(element, spec, callback, config, locale) {
  vega.timeFormatLocale(locale)

  return vegaEmbed(element, spec, {
    renderer: "canvas",
    actions: false
    // i18n: {
    //   CLICK_TO_VIEW_ACTIONS: t('clickaction'),
    //   PNG_ACTION: t('pngaction'),
    //   SVG_ACTION: t('svgaction')
    // }
  })
    .then(function (result) {
      result.view.key = config.key

      callback(result.view, config.key)
    })
    .catch(console.error)
}
