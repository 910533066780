import { combineReducers } from "redux"
import * as actions from "../actions"

function appinfo(state = null, action) {
  switch (action.type) {
    case actions.SET_APP_INFO:
      return action.appinfo

    default:
      return state
  }
}

function errors(state = [], action) {
  switch (action.type) {
    case actions.SET_ERROR:
      if (action.error && (action.error.clear || typeof action.error.index !== "undefined")) {
        if (action.error.clear) {
          state = []
        } else {
          state.splice(action.error.index, 1)
        }
      } else {
        // Nieuwe error toevoegen aan bestaande errors
        state.push(action.error)
      }

      return [...state]

    default:
      return state
  }
}

function loading(state = 0, action) {
  switch (action.type) {
    case actions.LOADING_STATE:
      return state + action.loading

    default:
      return state
  }
}

function locationLoading(state = 0, action) {
  switch (action.type) {
    case actions.LOCATION_LOADING_STATE:
      return state + action.locationLoading

    default:
      return state
  }
}

function timeDimensionLoading(state = false, action) {
  switch (action.type) {
    case actions.TIMEDIMENSION_LOADING_STATE:
      return action.loading

    default:
      return state
  }
}

export default combineReducers({
  appinfo,
  errors,
  loading,
  locationLoading,
  timeDimensionLoading
})
