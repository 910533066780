import L from "leaflet"

L.Control.Attribution = L.Control.extend({
  options: {
    position: "bottomright",
    prefix: "<a href='https://leafletjs.com' target='_blank'>Leaflet</a> | <a href='https://www.esri.com' target='_blank'>Esri</a> | <a href='https://www.hkv.nl/' target='_blank'>HKV Services</a>"
  },

  initialize: function (options) {
    L.setOptions(this, options);

    this._attributions = {};
  },

  onAdd: function (map) {
    this._container = L.DomUtil.create('div', 'leaflet-control-attribution');
    L.DomEvent.disableClickPropagation(this._container);

    map
      .on('layeradd', this._onLayerAdd, this)
      .on('layerremove', this._onLayerRemove, this);

    this._update();

    return this._container;
  },

  onRemove: function (map) {
    map
      .off('layeradd', this._onLayerAdd)
      .off('layerremove', this._onLayerRemove);
  },

  setPrefix: function (prefix) {
    this.options.prefix = prefix;
    this._update();
    return this;
  },

  addAttribution: function (text) {
    if (!text) { return; }

    if (!this._attributions[text]) {
      this._attributions[text] = 0;
    }
    this._attributions[text]++;

    this._update();

    return this;
  },

  removeAttribution: function (text) {
    if (!text) { return; }

    this._attributions[text]--;
    this._update();

    return this;
  },

  _update: function () {
    if (!this._map) { return; }

    var attribs = [];

    for (var i in this._attributions) {
      if (this._attributions.hasOwnProperty(i) && this._attributions[i]) {
        attribs.push(i);
      }
    }

    var prefixAndAttribs = [];

    if (this.options.prefix) {
      prefixAndAttribs.push(this.options.prefix);
    }
    if (attribs.length) {
      prefixAndAttribs.push(attribs.join(', '));
    }

    this._container.innerHTML = prefixAndAttribs.join(' | ');
  },

  _onLayerAdd: function (e) {
    if (e.layer.getAttribution) {
      this.addAttribution(e.layer.getAttribution());
    }
  },

  _onLayerRemove: function (e) {
    if (e.layer.getAttribution) {
      this.removeAttribution(e.layer.getAttribution());
    }
  }
})

L.control.attribution = function (options) {
  return new L.Control.Attribution(options);
}