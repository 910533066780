import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import asyncActions from "../../../state/async-actions"

import ReactResizeDetector from "react-resize-detector"

import { createVega } from "./vegaUtils"

import "./HKVVega.css"

function HKVVega(props) {
  const { config, dispatch, vegaDidMount } = props
  const { height, width } = props // evt kan er vanaf boven een width en height worden meegeven

  const { t } = useTranslation()

  const locale = {
    dateTime: "%a %e %B %Y %T",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
    shortDays: [t("shortsunday"), t("shortmonday"), t("shorttuesday"), t("shortwednesday"), t("shortthursday"), t("shortfriday"), t("shortsaturday")],
    months: [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
    shortMonths: [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"), t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")]
  }

  const [fetchedSpec, setFetchedSpec] = useState()
  const [viewObject, setViewObject] = useState()

  const vegaRef = React.createRef()

  useEffect(() => {
    let file = config.specName
    let local = true

    if (config.specUrl) {
      file = config.specUrl
      local = false
    }

    dispatch(asyncActions.loaderPlus())
      .then(() => {
        if (file) {
          dispatch(asyncActions.getVegaSpec(file, local))
            .then((spec) => {
              setFetchedSpec(spec)
            })
        } else {
          dispatch(asyncActions.setError({ message: "Er is geen vega spec-name/ url meegegeven", type: "error" }))
          dispatch(asyncActions.loaderMin())
        }
      })
  }, [])

  useEffect(() => {
    if (fetchedSpec) {
      createVega(vegaRef.current, fetchedSpec, mountVega, config, locale)
    }
  }, [fetchedSpec])

  const mountVega = (viewObject, key) => {
    setViewObject(viewObject)

    const element = document.getElementById(`vega-container-${config.key}`)
    dispatch(asyncActions.sendSignal(viewObject, { signalName: "width", value: element.offsetWidth }))
    dispatch(asyncActions.sendSignal(viewObject, { signalName: "height", value: element.offsetHeight }))

    config.signalValues && config.signalValues.forEach(signalValue => {
      dispatch(asyncActions.sendSignal(viewObject, { signalName: signalValue.name, value: signalValue.value }))
    })

    vegaDidMount(viewObject, key)
  }

  const handleResize = (w, h) => {
    if (viewObject) {
      dispatch(asyncActions.sendSignal(viewObject, { signalName: "width", value: w }))
      dispatch(asyncActions.sendSignal(viewObject, { signalName: "height", value: h }))
    }
  }

  return (
    <ReactResizeDetector onResize={handleResize} skipOnMount>
      <div
        ref={vegaRef}
        id={`vega-container-${config.key}`}
        style={{
          position: config.position ? config.position : "relative",
          width: width ? width : "100%",
          height: height ? height : "100%",
          ...props.style
        }}
      />
    </ReactResizeDetector>
  )
}

export default HKVVega