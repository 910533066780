import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Box,
  Paper,
  Popover,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material"

import {
  ChevronRightRounded
} from "@mui/icons-material"

import { makeStyles } from "@mui/styles"

import HKVDrawer from "./HKVDrawer"
import HKVIcon from "../../HKVIcon/1.0"

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1.5, 0),
    margin: theme.spacing(0, -1),
    cursor: "pointer",
    textAlign: "center",
    position: "relative",
    "&:hover": {
      "& svg:last-of-type": {
        opacity: 1,
        right: theme.spacing(-0.5)
      }
    }
  },
  subMenuItem: {
    padding: `${theme.spacing(1, 0)} !important`,
    "&:hover": {
      background: theme.palette.secondary.main
    },
    "&:last-of-type": {
      margin: `${theme.spacing(0, -1, -1)} !important`
    }
  },
  popoverItem: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(0, -2),
    padding: theme.spacing(0.5, 2),
    color: "inherit !important",
    textDecoration: "none",
    "&:hover": {
      background: theme.palette.action.hover
    }
  }
}))

function IconMenu(props) {
  const {
    disclaimer,
    help,
    languages,
    menuItems,
    onItemClick,
    onToggle,
    openDialog,
    privacyStatement,
    signout,
    selectedItem,
    selectedLanguage,
    setSelectedLanguage
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(theme)

  const [languageSelector, setLanguageSelector] = useState({ state: false, acnhor: 0 })

  const toggleLngSelect = (event) => {
    setLanguageSelector(languageSelector => {
      languageSelector.state = !languageSelector.state
      languageSelector.anchor = event.currentTarget

      return { ...languageSelector }
    })
  }

  const renderLanguageSelector = () => {
    const currentLanguage = languages.find(l => l.short === selectedLanguage)

    if (!languages || !currentLanguage) return null

    return (
      <React.Fragment>
        <Tooltip title={t("Select_language")} placement="right" sx={{ marginTop: theme.spacing(-0.25), marginLeft: 0 }}>
          <Box
            className={`${classes.menuItem} ${classes.subMenuItem}`}
            sx={{
              "&:hover": {
                background: "transparent !important"
              }
            }}
            onClick={toggleLngSelect}
          >
            <img
              src={`static/images/${currentLanguage.path}.png`}
              width={26}
              alt={t(selectedLanguage)}
            />
          </Box>
        </Tooltip>
        <Popover
          open={languageSelector.state}
          anchorEl={languageSelector.anchor}
          onClose={toggleLngSelect}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top"
          }}
        >
          <Paper sx={{ padding: theme.spacing(1, 2) }}>
            {languages.map((language, index) => (
              <div
                key={index}
                className={`${classes.popoverItem} transition`}
                style={{
                  background: selectedLanguage === language.short && theme.palette.action.selected,
                  cursor: "pointer"
                }}
                onClick={(event) => {
                  setSelectedLanguage(language.short)
                  toggleLngSelect(event)
                }}
              >
                <Typography>{t(language.long)}</Typography>
              </div>
            ))}
          </Paper>
        </Popover>
      </React.Fragment>
    )
  }

  return (
    <HKVDrawer
      defaultOpen={true}
      header="img"
      image="static/images/avatar.png"
      onToggle={onToggle}
      placement="left-mini"
      toggleButton={false}
      variant="permanent"
    >
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginTop: theme.spacing(1) }}>
        {menuItems.map((menuItem, index) => (
          <Tooltip key={index} title={menuItem.name} placement="right" sx={{ marginTop: theme.spacing(-0.25), marginLeft: 0 }}>
            <Box
              className={`${classes.menuItem} transition`}
              onClick={() => onItemClick(menuItem)}
              sx={{
                background: selectedItem && selectedItem.key === menuItem.key ? theme.palette.secondary.main : "transparent",
                "&:hover": {
                  background: selectedItem && selectedItem.key === menuItem.key ? theme.palette.secondary.dark : theme.palette.secondary.main
                }
              }}
            >
              <HKVIcon
                iconName={menuItem.icon}
                style={{
                  fontSize: theme.typography.pxToRem(30),
                  fill: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                }}
              />
              <ChevronRightRounded
                color="primary"
                className="transition"
                sx={{
                  position: "absolute",
                  opacity: 0,
                  right: theme.spacing(1),
                  transform: selectedItem && selectedItem.key === menuItem.key ? "rotate(180deg)" : "unset"
                }}
              />
            </Box>
          </Tooltip>
        ))}
      </div>
      {Boolean(languages && selectedLanguage && setSelectedLanguage) && renderLanguageSelector()}
      {Boolean(privacyStatement) && <Tooltip title={t("Privacy_statement")} placement="right" sx={{ marginTop: theme.spacing(-0.25), marginLeft: 0 }}>
        <Box
          className={`${classes.menuItem} ${classes.subMenuItem} transition`}
          onClick={() => openDialog("privacy_statement")}
        >
          <HKVIcon
            iconName="LockOutlined"
            style={{
              fontSize: theme.typography.pxToRem(30),
              fill: theme.palette.primary.main,
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Tooltip>}
      {Boolean(help) && <Tooltip title={t("Help")} placement="right" sx={{ marginTop: theme.spacing(-0.25), marginLeft: 0 }}>
        <Box
          className={`${classes.menuItem} ${classes.subMenuItem} transition`}
          onClick={() => openDialog("help")}
        >
          <HKVIcon
            iconName="HelpOutlineRounded"
            style={{
              fontSize: theme.typography.pxToRem(30),
              fill: theme.palette.primary.main,
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Tooltip>}
      {Boolean(disclaimer) && <Tooltip title={t("Disclaimer")} placement="right" sx={{ marginTop: theme.spacing(-0.25), marginLeft: 0 }}>
        <Box
          className={`${classes.menuItem} ${classes.subMenuItem} transition`}
          onClick={() => openDialog("disclaimer")}
        >
          <HKVIcon
            iconName="ErrorOutlineRounded"
            style={{
              fontSize: theme.typography.pxToRem(30),
              fill: theme.palette.primary.main,
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Tooltip>}
      {Boolean(signout) && <Tooltip title={t("Signout")} placement="right" sx={{ marginTop: theme.spacing(-0.25), marginLeft: 0 }}>
        <Box
          className={`${classes.menuItem} ${classes.subMenuItem} transition`}
          onClick={() => openDialog("signout", "small")}
        >
          <HKVIcon
            iconName="ExitToAppRounded"
            style={{
              fontSize: theme.typography.pxToRem(30),
              fill: theme.palette.primary.main,
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Tooltip>}
      {props.children}
    </HKVDrawer>
  )
}

export default IconMenu