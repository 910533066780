// import proj4 from "proj4"

// const rdProjection = "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +no_defs"
// const wgsProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs "

// export function RDToWGS(x, y) {
//   const lnglatWSG84 = proj4(rdProjection, wgsProjection, [x, y])

//   return [lnglatWSG84[1], lnglatWSG84[0]]
// }

// export function WGSToRD(lat, lng) {
//   const lnglatRD = proj4(wgsProjection, rdProjection, [lng, lat])

//   return [lnglatRD[0], lnglatRD[1]]
// }

import L from "leaflet"
// NonTiledLayer voor bepaalde timeDimensionLayers
import "leaflet.nontiledlayer"
import "leaflet-timedimension"

import moment from "moment"

export async function createTimeDimensionLayer(mapRef, groupRef, layer, config, callback) {
  const paneKey = layer.pane ? layer.pane : `${layer.key}Pane`
  const opacity = layer.options && layer.options.opacity !== undefined ? layer.options.opacity : 1

  // NonTiledLayer aanmaken, deze koppelen we later aan een timedimension wms laag
  let wmsLayer = L.nonTiledLayer.wms(layer.wmsUrl, {
    pane: paneKey,
    styles: layer.styles,
    layers: layer.layer,
    format: "image/png",
    transparent: true,
    opacity: opacity
  })

  // Paar player options
  const transitionTime = 1000
  const loop = true

  // Laag heeft zijn eigen contoller
  if (config.timeDimensionIndividualControl) {
    // Eigen TD maken
    const timeDimension = new L.TimeDimension()

    // Eigen TD Control maken
    const timeDimensionControl = new L.Control.TimeDimension({
      autoPlay: Boolean(layer.autoPlay),
      displayDate: true,
      // period: "PT5M",
      playerOptions: {
        autoPlay: Boolean(layer.autoPlay), // Hiermee bepalen we de initiele buttonStatus
        transitionTime: transitionTime, // (1000 / transitionTime)
        loop: loop
      }
      // timeZones: ["Local", "UTC"]
    })

    // TD koppelen aan mapRef
    // mapRef.timeDimension = timeDimension

    // TD+TD Control aan groupRef koppelen
    groupRef.timeDimension = timeDimension
    groupRef.timeDimensionControl = timeDimensionControl

    // TD Control aan mapRef koppelen
    mapRef.addControl(timeDimensionControl)
    // mapRef.addControl(timeDimensionControl)
    // mapRef.timeDimensionControl = timeDimensionControl
  } else {
    // Laag heeft één algemene controller
    const player = mapRef.timeDimensionControl._player

    // Hiermee bepalen we de initiele buttonStatus
    player.options.autoPlay = config.timeDimensionControlAutoPlay
    if (config.timeDimensionControlAutoPlay === true) {
      player.start()
    }

    // Standaard player opties zetten
    player.setTransitionTime(transitionTime)
    player.setLooped(loop)
  }

  // Kan je evt zetten naar aantal availableTimes in het <HKVTimeDimensionControl /> (zoek naar 'cache')
  const cache = 0
  // const cache = 10

  // Standaard timeDimension opties
  const timeDimensionOptions = {
    cache: cache,
    cacheBackward: cache,
    cacheForward: cache,
    updateTimeDimension: true,
    updateTimeDimensionMode: "replace",// config.timeDimensionIndividualControl ? "replace" : "union" // intersect, union, replace, extremes,,
    getCapabilitiesUrl: 'rainguru'
  }

  // Als per layer een controller moet zijn moeten de lagen ook aan de groupRef worden gekoppeld ipv aan de mapRef
  if (config.timeDimensionIndividualControl) {
    timeDimensionOptions.tdRef = groupRef
  }

  let tdLayer = null
  // Als we zelf de getCapabilities (of andere manier) van beschikbare tijden willen ophalen
  if (layer.getCapabilities === false) {
    // if (layer.times) {
    //   if (config.timeDimensionIndividualControl) {
    //     // Timedimension control availableTimes zetten op basis van mapConfig layer zijn 'times'
    //     groupRef.timeDimension.setAvailableTimes(layer.times, "replace") // invidual control willen we overschrijven
    //     // Eerst beschikbare tijd selecteren
    //     groupRef.timeDimension.setCurrentTime(layer.times[0])
    //   } else {
    //     // Timedimension control availableTimes zetten op basis van mapConfig layer zijn 'times'
    //     // mapRef.timeDimension.setAvailableTimes(layer.times, "union") // single control willen tijden we samenvoegen
    //     mapRef.timeDimension.setAvailableTimes(layer.times, "replace") // single control willen tijden we samenvoegen
    //   }
    // }

    // Default voor createUrl
    const createUrl = function (baseUrl, time) {
      const url = `${baseUrl}/${time}/{z}/{x}/{y}`

      return url
    }

    if (layer.imageLayer) {
      tdLayer = L.timeDimension.layer.imageOverlay(wmsLayer, {
        // Als gebruiker een eigen 'createUrl' in layer heeft, gebruik deze, anders de default
        getUrlFunction: Boolean(layer.createUrl) ? layer.createUrl : createUrl,
        tdRef: config.timeDimensionIndividualControl ? groupRef : null
      })
    } else {
      // timeDimensionOptions.getUrlFunction = layer.createUrl ? layer.createUrl : createUrl
      // timeDimensionOptions.request = "GetMap"

      tdLayer = L.timeDimension.layer.wms(wmsLayer, timeDimensionOptions)
    }
  } else {
    // Default (capabilities uit WebService halen)
    const startTime = moment.utc().startOf("day").toISOString()
    const endTime = moment.utc().startOf("day").add(1, "days").toISOString()

    timeDimensionOptions.getCapabilitiesParams = {
      startTime: startTime,
      endTime: endTime,
      forecastCount: config.timeDimensionIndividualControl ? 1 : config.timeDimensionLayers.length, // Number of forecast runs to return when using start- and end- forecast time. Default is 1.
      layers: layer.layer,
      importFromExternalDataSource: true // apply seamless integration with the archive. Only valid when specifying a forecastPeriod (using startForecastTime, endForecastTime and forecastCount) and layers.
    }
    timeDimensionOptions.getCapabilitiesLayerName = layer.layer
    timeDimensionOptions.refreshTime = 60000 // default is 5 minuten
    timeDimensionOptions.requestTimeFromCapabilities = true // Get list of available times for this layer from getCapabilities
    timeDimensionOptions.updateTimeDimension = true // Update the list of available times of the attached TimeDimension with the available times obtained by getCapabilities

    // tdLayer = L.timeDimension.layer.wms.autoRefresh(wmsLayer, timeDimensionOptions)
    tdLayer = L.timeDimension.layer.wms(wmsLayer, timeDimensionOptions)
  }

  if (config.timeDimensionIndividualControl) {
    // tdLayer.addTo(groupRef)
  } else {
    mapRef.addLayer(tdLayer)
    // tdLayer.addTo(mapRef)
  }

  if (callback) {
    callback(groupRef)
  }
}