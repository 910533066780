import * as actions from "../actions"
import asyncActions from "../async-actions"

import {
  catchMessage,
  getUploadUrl
} from "./environment"

import moment from "moment"

export const getAppInfo = (parameters = {}, body = {}) => async (dispatch) => {
  const functionName = "mangrove.appfunctions.GetAppInfo"
  const url = getUploadUrl(functionName)

  return await makeRequest(url, functionName, "POST", parameters, body)
    .then(response => JSON.parse(response))
    .then(json => {
      dispatch(setAppInfoInState(json))

      if (json) {
        if (json.exception) {
          catchMessage(json, dispatch, functionName)
          return ["success", "info"].includes(json.type) ? json : null
        } else {
          return json
        }
      } else {
        catchMessage({ exception: "Error" }, dispatch, functionName)
        return null
      }
    })
    .catch(function (error) {
      dispatch(catchMessage({ exception: error }, functionName))
    })
}

export function loaderPlus(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? 1 : amount) }) // 0 = 0, undefined = 1, >0 = amount
  }
}

export function loaderMin(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? -1 : -amount) })
  }
}

export function loaderTimeDimension(bool) {
  return async function (dispatch) {
    dispatch({ type: actions.TIMEDIMENSION_LOADING_STATE, loading: bool })
  }
}

export function setError(error) {
  return async function (dispatch) {
    // Unieke key hangen aan error
    if (error) { error.key = moment().valueOf() }
    dispatch({ type: actions.SET_ERROR, error: error })
  }
}

export function setAppInfoInState(appinfo) {
  return {
    type: actions.SET_APP_INFO,
    appinfo
  }
}