import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import asyncActions from "./state/async-actions"

import { useInterval } from "usehooks-ts"

import {
  Alert,
  Button,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
  useTheme
} from "@mui/material"

import HKVDialog from "./components/HKVDialog/6.0"

import {
  Link,
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom"

import { Events, Root } from "./pages"

import { getAppType } from "./state/async-actions/environment"

import moment from "moment"

import HKVLoader from "./hkv_loader"

import packageJson from "../package.json"

function App(props) {
  const { application, database, dispatch } = props

  const { t } = useTranslation()
  const theme = useTheme()

  const [dataInterval, setDataInterval] = useState(true)

  useEffect(() => {
    if (getAppType() === "app") {
      document.addEventListener("deviceready", onDeviceReady, true)
    }

    document.addEventListener("visibilitychange", function () {
      visibilityChanged(document.visibilityState)
    })
  }, [])

  const onDeviceReady = () => {
    navigator.splashscreen.hide()
  }

  const visibilityChanged = (state) => {
    if (state === "visible") {
      setDataInterval(true) // Elke x minuten data ophalen
    } else {
      setDataInterval(false)
    }
  }

  const closeSnackbar = (reason, index) => {
    if (reason === "clickaway") return null

    dispatch(asyncActions.setError({ index: index }))
  }

  const renderErrors = () => {
    const errors = application.errors.filter(error => error.type === "error" || error.type === "warning")

    return (
      <HKVDialog
        config={{ open: errors.length > 0, size: "small" }}
        onClose={() => props.dispatch(asyncActions.setError({ clear: true }))}
      >
        <DialogTitle>{errors.length > 1 ? "Er zijn meerdere fouten opgetreden" : "Er is een fout opgetreden"}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", maxWidh: "100%", maxHeight: 400 }}>
          <div style={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
            {errors.map((error, index) => (
              <div key={index} style={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: theme.shape.borderRadius, padding: theme.spacing(0.5, 1), marginTop: index === 0 ? 0 : theme.spacing(0.5) }}>
                <div style={{ display: "flex" }}>
                  <Typography sx={{ marginRight: theme.spacing(1), width: 60 }}>{t("Melding")}:</Typography>
                  <Typography><b>{error.message}</b></Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography sx={{ marginRight: theme.spacing(1), width: 60 }}>{t("Functie")}:</Typography>
                  <Typography><b>{error.function}</b></Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography sx={{ marginRight: theme.spacing(1), width: 60 }}>{t("Tijdstip")}:</Typography>
                  <Typography><b>{moment(error.key).format("DD-MM HH:mm:ss")}</b></Typography>
                </div>
                {error.type === "error" && <div style={{ display: "flex" }}>
                  <Typography sx={{ marginRight: theme.spacing(1), width: 60 }}>{t("Contact")}:</Typography>
                  <Typography><a href={`mailto:ontwikkelaars@hkv.nl?subject=${application.appinfo ? application.appinfo.name : packageJson.name} error&body=Applicatie: ${application.appinfo ? application.appinfo.name : packageJson.name}%0D%0AMelding: ${error.message}%0D%0AFunctie: ${error.function}%0D%0ACode: ${error.number}`}><b>ontwikkelaars@hkv.nl</b></a></Typography>
                </div>}
              </div>
            ))}
          </div>
          <div className="dialog-actions">
            <Button
              variant="contained"
              color="primary"
              className="dialog-button"
              onClick={() => props.dispatch(asyncActions.setError({ clear: true }))}
            >
              OK
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>
    )
  }

  const renderNotifications = () => {
    const notifications = application.errors.filter(error => error.type === "info" || error.type === "success")

    const height = 50
    return notifications.map((notification, index) => {
      const bottom = (height * index)

      return (
        <Snackbar
          disableWindowBlurListener
          key={`${notification.key}-${index}`}
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={notification.duration ? notification.duration : 4000}
          onClose={(event, reason) => closeSnackbar(reason, index)}
          onClick={() => closeSnackbar("click", index)}
          sx={{
            zIndex: 99999999,
            width: "fit-content",
            maxWidth: `calc(100% - ${theme.spacing(2)})`,
            height: height,
            left: 8,
            right: 8,
            margin: "0 auto",
            bottom: `calc(${bottom}px + ${theme.spacing(1 * (index + 1))}) !important`
          }}
        >
          <Alert elevation={2} severity={notification.type}>
            <Typography className="ellipsis">
              {notification.message.toString()}
            </Typography>
          </Alert>
        </Snackbar>
      )
    })
  }

  const renderLoader = () => {
    if (application.loading > 0 || application.timeDimensionLoading) {
      return (
        <div className="loader-background">
          <img rel="preload" as="image" src={HKVLoader} alt="HKV loader" className="loader-default" />
        </div>
      )
    } else return null
  }

  return (
    <div className="App">
      {renderLoader()}
      {renderErrors()}
      {renderNotifications()}
      <Routes>
        {["/", "*"].map((path, index) => (
          <Route
            key={index}
            path={path}
            element={<Root dataInterval={dataInterval} />}
          />
        ))}
        {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
        {["events", "events/:id", "/", "*"].map((path, index) => (
          <Route
            key={index}
            path={path}
            element={<Events />}
          />
        ))}
      </Routes>
    </div>
  )
}

export default connect(state => state)(App)