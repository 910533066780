import * as application from "./application"
import * as database from "./database"
import * as environment from "./environment"
import * as vega from "./vega"

export default {
  ...application,
  ...database,
  ...environment,
  ...vega
}