import React from "react"
import { connect } from "react-redux"
import { withStyles } from "@mui/styles"
import { withTranslation } from "react-i18next"

import asyncActions from "../../../state/async-actions"

import {
  Paper,
  Slider,
  Typography
} from "@mui/material"

import {
  ChevronLeftRounded,
  ChevronRightRounded,
  PauseRounded,
  PlayArrowRounded
} from "@mui/icons-material"

import moment from "moment"

import "./HKVTimeDimensionControl.css"

const styles = theme => ({
  timeDimensionContainer: {
    zIndex: 1,
    pointerEvents: "none",
    [theme.breakpoints.down(740)]: {
      marginBottom: 20
    },
    [theme.breakpoints.up(740)]: {
      marginBottom: 0
    }
  },
  sliderRoot: {
    padding: "11px 0",
    cursor: "pointer"
  },
  sliderThumb: {
    boxShadow: "unset !important",
    borderRadius: 0,
    height: 14,
    width: 6
  },
  sliderTrack: {
    height: 4
  },
  sliderMark: {
    display: "none"
  },
  timeDimensionControlFPS: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  tdButton: {
    display: "flex",
    padding: theme.spacing(0.5),
    cursor: "pointer",
    border: "1px solid lightgray",
    marginRight: theme.spacing(0.5),
    "&:last-of-type": {
      marginRight: 0
    }
  },
  tdButtonDisabled: {
    pointerEvents: "none",
    color: "#bdbdbd"
  }
})

class HKVTimeDimensionControl extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      FPSMarks: [
        { value: 100, name: 10 },
        { value: 105, name: 9.5 },
        { value: 111, name: 9 },
        { value: 118, name: 8.5 },
        { value: 125, name: 8 },
        { value: 133, name: 7.5 },
        { value: 143, name: 7 },
        { value: 154, name: 6.5 },
        { value: 167, name: 6 },
        { value: 182, name: 5.5 },
        { value: 200, name: 5 },
        { value: 222, name: 4.5 },
        { value: 250, name: 4 },
        { value: 285, name: 3.5 },
        { value: 333, name: 3 },
        { value: 400, name: 2.5 },
        { value: 500, name: 2 },
        { value: 666, name: 1.5 },
        { value: 1000, name: 1 }
      ]
    }

    if (this.props.featureGroup) {
      this.tdRef = this.props.featureGroup
    } else if (this.props.mapRef) {
      this.tdRef = this.props.mapRef
    } else {
      this.tdRef = {}
    }
  }

  componentDidMount = () => {
    const { onTimeLoad, t } = this.props
    const { FPSMarks } = this.state

    if (!this.tdRef.timeDimension || !this.tdRef.timeDimensionControl) return null

    const player = this.tdRef.timeDimensionControl._player

    const currentLabel = FPSMarks.find(m => m.value === player.getTransitionTime()).name

    onTimeLoad && onTimeLoad(this.tdRef.timeDimension.getCurrentTime())

    // Initiele statussen zetten voor buttons en labels
    this.setState({
      isPlaying: player.options.autoPlay,
      timeDimensionCurrentSpeedLabel: currentLabel,
      timeDimensionCurrentTime: this.tdRef.timeDimension.getCurrentTime()
    })

    const that = this

    // Het kan voorkomen dat er 2 controls voor dezelfde layer zijn dus vandaag een on() event ipv in de play/pause functie
    player.on("play", function (data) {
      that.setState({ isPlaying: true })
    })

    player.on("stop", function (data) {
      that.setState({ isPlaying: false })
    })

    player.on("waiting", function (data) {
      that.props.dispatch(asyncActions.loaderTimeDimension(true))
    })

    // Het kan voorkomen dat we het control mounten met een standaard map controller, daarom hangen we hier nog een event listener aan de player
    player.on("speedchange", function (data) {
      const currentTransitionTime = FPSMarks.find(m => m.name === that.state.timeDimensionCurrentSpeedLabel).value
      // En zetten we het label in de state als de transitionTime inderdaad anders is
      if (currentTransitionTime !== data.transitionTime) {
        that.setState({ timeDimensionCurrentSpeedLabel: 1000 / data.transitionTime })
      }
    })

    // Als nieuwe tijdstap heeft gemaakt
    this.tdRef.timeDimension.on("timeload", function (data) {
      // that.props.dispatch(asyncActions.loaderTimeDimension(false))
      that.setState({ timeDimensionCurrentTime: data.time })
      onTimeLoad && onTimeLoad(data.time)
    })

    // Als aan het laden is
    // this.tdRef.timeDimension.on("timeloading", function (data) {
    //   if (data.time === that.tdRef.timeDimension.getCurrentTime()) {
    //     that.props.dispatch(asyncActions.loaderTimeDimension(true))
    //   } else {
    //     if (data.time) {
    //       that.props.dispatch(asyncActions.loaderTimeDimension(false))
    //     }
    //   }
    // })

    // Als nieuwe tijden beschikbaar
    this.tdRef.timeDimension.on("availabletimeschanged", function (data) {
      const currentTime = that.tdRef.timeDimension.getCurrentTime()

      // Als huidige tijd niet in de nieuwe beschikbare tijden staat zet dan de currentTime op de dichtstbijzijnde
      if (!data.availableTimes.includes(currentTime)) {
        // Als de currentTime niet in de beschikbare tijden staat, zet de currentTime op de dichtsbijzijnde
        const closestTime = that.findClosest(data.availableTimes, currentTime)

        that.tdRef.timeDimension.setCurrentTime(closestTime)
      }

      // // Overschrijven van het aantal files we in cache willen hebben
      // this.tdRef.eachLayer(featureLayer => {
      //   featureLayer._timeCacheBackward = data.availableTimes.length
      //   featureLayer._timeCacheForward = data.availableTimes.length
      // })
    })

    const loader = document.getElementById(`loading-${this.tdRef.key}`)

    let i = 0
    if (loader) {
      this.dots = window.setInterval(function () {
        if (i > 2) {
          i = 0
          loader.innerHTML = t("Laden")
        } else {
          i++
          loader.innerHTML += "."
        }
      }, 500)
    } else {
      clearInterval(this.dots)
    }
  }

  findClosest = (array, number) => {
    if (array.length === 0) return number

    const closest = array.reduce((a, b) => {
      return Math.abs(b - number) < Math.abs(a - number) ? b : a
    })

    return closest
  }

  setPreviousTime = () => {
    this.tdRef.timeDimension.previousTime(1, true) // steps, loop
  }

  setNextTime = () => {
    this.tdRef.timeDimension.nextTime(1, true) // steps, loop
  }

  playPausePlayer = () => {
    const { isPlaying } = this.state

    const player = this.tdRef.timeDimensionControl._player
    isPlaying ? player.stop() : player.start()
  }

  changeTimeDimensionTime = (event, value, marks) => {
    let currentTime = value
    if (marks) {
      currentTime = marks.find(m => m.value === value).time
    }

    this.tdRef.timeDimension.setCurrentTime(currentTime)
  }

  changeTimeDimensionSpeed = (event, value, commit) => {
    const { FPSMarks } = this.state

    const label = FPSMarks.find(m => m.name === value).value

    this.setState({ timeDimensionCurrentSpeedLabel: value }, () => {
      if (commit) {
        const player = this.tdRef.timeDimensionControl._player
        player.setTransitionTime(label)
      }
    })
  }

  createDefaultTDLabel = () => {
    const { labelFormat } = this.props
    const { timeDimensionCurrentTime } = this.state

    if (isNaN(moment(timeDimensionCurrentTime).valueOf())) {
      return timeDimensionCurrentTime
    } else {
      return moment(timeDimensionCurrentTime).subtract(1, "hours").format(labelFormat ? labelFormat : "DD-MM-YYYY HH:mm")
    }
  }

  createTDLabel = () => {
    const { createLabel, layer } = this.props
    const { timeDimensionCurrentTime } = this.state

    let label = ""

    if (!timeDimensionCurrentTime) return label

    if (layer) {
      if (layer.createLabel) {
        label = layer.createLabel(layer.key, timeDimensionCurrentTime)
      } else {
        label = this.createDefaultTDLabel()
      }
    } else {
      if (createLabel) {
        label = createLabel("test", timeDimensionCurrentTime)
      } else {
        label = this.createDefaultTDLabel()
      }
    }

    return label
  }

  renderTimeDimensionControl = () => {
    const { classes, fps, t, theme, timeLabel, timeSlider } = this.props
    const { isPlaying, timeDimensionCurrentTime, timeDimensionCurrentSpeedLabel } = this.state

    if (!this.tdRef.timeDimension) return null

    const times = this.tdRef.timeDimension.getAvailableTimes()

    let marks = null
    // Minimaal 2 times nodig voor een valide step
    let step = null
    if (times.length > 1) {
      step = times[1] - times[0]
    }

    // Als we geen gebruik maken van een tijden in MS, maar sting tijden
    marks = []
    times.forEach((time, index) => {
      marks.push({ value: isNaN(step) ? index : time, time: time })
    })

    return (
      <div style={{ display: "flex", pointerEvents: "auto" }}>
        <Paper
          onClick={this.setPreviousTime}
          className={`${classes.tdButton} ${times.length === 0 && classes.tdButtonDisabled}`}
        >
          <ChevronLeftRounded />
        </Paper>
        <Paper
          onClick={this.playPausePlayer}
          className={`${classes.tdButton} ${times.length === 0 && classes.tdButtonDisabled}`}
        >
          {isPlaying ? <PauseRounded /> : <PlayArrowRounded />}
        </Paper>
        <Paper
          onClick={this.setNextTime}
          className={`${classes.tdButton} ${times.length === 0 && classes.tdButtonDisabled}`}
        >
          <ChevronRightRounded />
        </Paper>
        {(timeLabel !== false || timeSlider !== false) && <Paper
          square
          className={classes.timeDimensionControlTime}
          sx={{ display: "flex", alignItems: "center", width: "100%", padding: theme.spacing(0.5), border: "1px solid lightgray" }}
        >
          {timeLabel !== false && <Typography sx={{ flexShrink: 0, margin: "0px 0px -1px" }}>
            {times.length === 0 ? <Typography id={`loading-${this.tdRef.key}`} sx={{ minWidth: 70 }}>{t("Laden")}</Typography> : this.createTDLabel()}
          </Typography>}
          {(timeSlider !== false && times.length > 1 && timeDimensionCurrentTime) && <Slider
            // Als steps NaN is (bij strings ipv tijden)
            value={isNaN(step) ? marks.find(m => m.time === timeDimensionCurrentTime).value : timeDimensionCurrentTime}
            step={isNaN(step) ? 1 : step}
            min={isNaN(step) ? marks[0].value : times[0]}
            max={isNaN(step) ? marks[marks.length - 1].value : times[times.length - 1]}
            sx={{ minWidth: 140, margin: theme.spacing(0, 1) }}
            marks={marks}
            classes={{
              root: classes.sliderRoot,
              thumb: classes.sliderThumb,
              focusVisible: classes.sliderThumb,
              mark: classes.sliderMark,
              track: classes.sliderTrack,
              rail: classes.sliderTrack
            }}
            track={false}
            onChange={(event, value) => this.changeTimeDimensionTime(event, value, isNaN(step) && marks)}
          // onChangeCommitted={(event, value) => this.changeTimeDimensionTime(event, value, isNaN(step) && marks)}
          />}
        </Paper>}
        {(fps !== false && timeDimensionCurrentSpeedLabel) && <Paper
          className={classes.timeDimensionControlFPS}
          sx={{ alignItems: "center", padding: theme.spacing(0.5), border: "1px solid lightgray" }}
        >
          <Typography sx={{ minWidth: 42, margin: "0px 0px -1px" }}>{timeDimensionCurrentSpeedLabel}fps</Typography>
          <Slider
            disabled={times.length === 0}
            value={timeDimensionCurrentSpeedLabel}
            min={1}
            step={1}
            max={10}
            sx={{ minWidth: 100, margin: theme.spacing(0, 1) }}
            classes={{
              root: classes.sliderRoot,
              thumb: classes.sliderThumb,
              focusVisible: classes.sliderThumb,
              mark: classes.sliderTrack,
              track: classes.sliderTrack,
              rail: classes.sliderTrack
            }}
            onChange={(event, value) => this.changeTimeDimensionSpeed(event, value)}
            onChangeCommitted={(event, value) => this.changeTimeDimensionSpeed(event, value, true)}
          />
        </Paper>}
      </div>
    )
  }

  determineContainerStyle = (absolutePositions, position, index) => {
    const { theme } = this.props

    if (absolutePositions.includes(position)) {
      return {
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        bottom: `calc(${index} * (${34}px + ${theme.spacing(1)}) + ${theme.spacing(1)})`,
        left: position === "bottom" || position === "bottom-left" ? theme.spacing(1) : "unset",
        right: position === "bottom" || position === "bottom-right" ? theme.spacing(1) : "unset"
      }
    } else {
      return {}
    }
  }

  render() {
    const { classes, drawerStates, index, layer, position } = this.props

    const absolutePositions = ["bottom", "bottom-left", "bottom-right"]

    if (!this.tdRef.timeDimension || !this.tdRef.timeDimensionControl) return null

    return (
      <div
        className={
          `${absolutePositions.includes(position) && drawerStates && drawerStates.left ? "moveLAbsolute" : ""}
              ${absolutePositions.includes(position) && drawerStates && drawerStates.right ? "moveRAbsolute" : ""}
              transition ${classes.timeDimensionContainer}`
        }
        style={this.determineContainerStyle(absolutePositions, position, index ? index : 0)}
      >
        {this.renderTimeDimensionControl()}
      </div>
    )
  }
}

HKVTimeDimensionControl = withTranslation("translation")(HKVTimeDimensionControl)
HKVTimeDimensionControl = withStyles(styles, { withTheme: true })(HKVTimeDimensionControl)
export default connect(state => state)(HKVTimeDimensionControl)