import { combineReducers } from "redux"
import * as actions from "../actions"

function precipitation(state = [], action) {
  switch (action.type) {
    case actions.SET_PRECIPITATION:
      return action.precipitation

    default:
      return state
  }
}

export default combineReducers({
  precipitation
})