import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import i18nBackend from "./i18nBackend"

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "nl",
    languages: ["nl", "en"],
    ns: ["translation"],
    defaultNS: "translation",
    debug: false,
    keySeparator: ">",
    nsSeparator: "|",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })

export default i18n