import React from "react"
import { withStyles } from "@mui/styles"

import {
  Dialog,
  Paper
} from "@mui/material"

import "./HKVDialog.css"

const styles = theme => ({
  dialogContainer: {
    flexDirection: "column"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: 10,
    maxHeight: "100%"
  },
  xs: {
    maxWidth: 440,
    [theme.breakpoints.down(440 + 32)]: {
      margin: 0,
      borderRadius: 0
    },
    [theme.breakpoints.up(440 + 32)]: {
      margin: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    }
  },
  sm: {
    maxWidth: 660,
    [theme.breakpoints.down(660 + 32)]: {
      margin: 0,
      borderRadius: 0
    },
    [theme.breakpoints.up(660 + 32)]: {
      margin: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    }
  },
  md: {
    width: "100%",
    maxWidth: 960,
    [theme.breakpoints.down(960 + 32)]: {
      maxHeight: "100%",
      margin: 0,
      borderRadius: 0
    },
    [theme.breakpoints.up(960 + 32)]: {
      maxHeight: `calc(100% - ${theme.spacing(8)})`,
      margin: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    }
  },
  lg: {
    width: "100%",
    maxWidth: 1260,
    [theme.breakpoints.down(1260 + 32)]: {
      maxHeight: "100%",
      margin: 0,
      borderRadius: 0
    },
    [theme.breakpoints.up(1260 + 32)]: {
      maxHeight: `calc(100% - ${theme.spacing(8)})`,
      margin: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    }
  },
  xl: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: 0,
      borderRadius: 0
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: `calc(100% - ${theme.spacing(8)})`,
      maxHeight: `calc(100% - ${theme.spacing(8)})`,
      margin: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    }
  },
  full: {
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    borderRadius: 0,
    margin: 0
  }
})

function HKVDialog(props) {
  const { classes, config, noPaper, onClose } = props

  if (!config) return null

  return (
    <Dialog
      open={config.open}
      PaperComponent={noPaper ? "div" : Paper}
      onClose={(event, reason) => onClose && reason !== "backdropClick" ? onClose() : null}
      classes={{
        container: classes.dialogContainer,
        paper: `${classes.root} ${classes[config.size]}`
      }}
      maxWidth={false} // wij bepalen zelf dmv size wat de maxWidth etc moet zijn
      sx={{ flexDirection: "column" }}
    >
      {props.children}
    </Dialog>
  )
}

export default withStyles(styles, { withTheme: true })(HKVDialog)